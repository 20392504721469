import * as React from "react"
import { withPreviewResolver } from "gatsby-source-prismic"

const PreviewPage = props => {
  if (props.isPreview === false) return "Not a preview!"
  return <p>Loading</p>
}

export default withPreviewResolver(PreviewPage, {
  repositoryName: process.env.PRISMIC_ENDPOINT,
  linkResolver: ({ node, key, value }) => doc => {
    if (doc.type === "page") {
      if (doc.uid === "index") {
        return `/`
      }
      return `/${doc.uid}`
    }

    if (doc.type === "blog") return `/blog/${doc.uid}`

    return "/404"
  },
  fetchLinks: ["page.uid", "blog.uid"],
})
